import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Table } from 'antd';
import Card from '../../../components/bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import { getClearanceTimeExportReportService } from '../../../services/ClearanceReportService';
import moment from 'moment';
import { getTodaysDate, formatToTwoDecimals } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const ClearanceTimeExportReport = () => {
  const [data, setData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const tableRef = useRef(null);
  const username = localStorage.getItem('name');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log('Fetching clearance time export report data...');
      const response = await getClearanceTimeExportReportService(iec_no);
      // const processedData = preprocessData(response);
      setData(response);
    } catch (error) {
      console.error('Error fetching clearance time export report data:', error);
    }
  };

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn('Invalid date string:', dateString);
      return ''; // Return empty string if the dateString is not a valid date
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  const preprocessData = (data) => {
    return data.map(record => {
      // record.daysSubToLeo = calculateDaysDifference(record.submission_date, record.leo_date);

      record.sb_date = record.sb_date ? formatDateToDDMMYY(new Date(record.sb_date)) : '';
      record.leo_date = record.leo_date ? formatDateToDDMMYY(new Date(record.leo_date)) : '';
      record.submission_date = record.submission_date ? formatDateToDDMMYY(new Date(record.submission_date)) : '';

      return record;
    });
  };

  const calculateDaysDifference = (date1, date2) => {
    const diffTime = Math.abs(new Date(date2) - new Date(date1));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const columns = [
    { title: 'IEC/BR CODE', dataIndex: 'iec_br', key: 'iec_br', header: 'IEC/BR CODE' },
    { title: 'CB CODE', dataIndex: 'cb_code', key: 'cb_code', header: 'CB CODE' },
    { title: 'NAME', dataIndex: 'cb_name', key: 'cb_name', header: 'NAME' },
    { title: 'PORT', dataIndex: 'port_code', key: 'port_code', header: 'PORT' },
    { title: 'SHB NO.', dataIndex: 'sb_no', key: 'sb_no', header: 'SHB NO.' },
    { title: 'SHB DT.', dataIndex: 'sb_date', key: 'sb_date', header: 'SHB DT.' },
    { title: 'SUBMISSION DT.', dataIndex: 'submission_date', key: 'submission_date', header: 'SUBMISSION DT.' },
    { title: 'LEO DT.', dataIndex: 'leo_date', key: 'leo_date', header: 'LEO DT.' },
    { title: 'DAYS SUB. TO LEO', dataIndex: 'days_sub_to_leo', key: 'days_sub_to_leo', header: 'DAYS SUB. TO LEO', align: 'right' },
    { title: 'TERMS', dataIndex: 'invterm', key: 'invterm', header: 'TERMS' },
  ];


  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Data');


    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        submission_date: getDateOnly(obj.submission_date),
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date)
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - Clearance Time Export as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  const getDataToRender = () => {
    if (tableRef.current) {
      return data;
    }
    return [];
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  Lucrative's CIP TAT - Export Clearance as on {moment().format('DD/MM/YYYY')} of {username}
                </h4>
              </div>
              <div>
                <button className="btn btn-primary" onClick={exportToExcel}>Export to Excel</button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <Table dataSource={data} columns={columns} ref={tableRef} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ClearanceTimeExportReport;
