import React, { Fragment, useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getRodtepDataService } from '../../../services/RodtepReportService';
import { getReportSummaryData } from '../../../services/FileDataService';
import { determineRemark } from '../utils/RemarkAllocation';
import { getTodaysDate, formatToTwoDecimals } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const RodtepReport = ({ data }) => {
  const iec_no = localStorage.getItem('iec_no');
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [summaryDashboardData, setSummaryDashboardData] = useState([]);
  const username = localStorage.getItem('name');

  const today = new Date();
  // Extract day, month, and year components
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = today.getFullYear();

  // Concatenate components with hyphens
  const formattedDate = `${day}-${month}-${year}`;
  // Define columns for the table
  const columns = [
    {
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
      header: 'IEC/BR CODE' 
    },
    {
      title: 'PORT',
      dataIndex: 'port_code',
      key: 'port_code',
      header: 'PORT' 
    },
    {
      title: 'FY',
      dataIndex: 'fy',
      key: 'fy',
      header: 'FY' 
    },
    {
      title: 'SHB NO.',
      dataIndex: 'sb_no',
      key: 'sb_no',
      header: 'SHB NO.' 
    },
    {
      title: 'SHB DT.',
      dataIndex: 'sb_date',
      key: 'sb_date',
      header: 'SHB DT.' 
    },
    {
      title: 'LEO DT.',
      dataIndex: 'leo_date',
      key: 'leo_date',
      render: (text, record) => formatDateToDDMMYY(record.leo_date), 
      header: 'LEO DT.' 
    },
    {
      title: 'RODTEP AMT.',
      dataIndex: 'rodtep_amt',
      key: 'rodtep_amt',
      header: 'RODTEP AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'SCROLL NO. / QUERY',
      dataIndex: 'rodtep_scroll_number',
      key: 'rodtep_scroll_number',
      header: 'SCROLL NO. / QUERY' 
    },
    {
      title: 'SCROLL DT.',
      dataIndex: 'rodtep_scroll_date',
      key: 'rodtep_scroll_date',
      render: (text, record) => record.rodtep_scroll_date ? formatDateToDDMMYY(record.rodtep_scroll_date) : '', 
      header: 'SCROLL DT.' 
    },
    {
      title: 'SCROLL AMT.',
      dataIndex: 'rodtep_scroll_amount',
      key: 'rodtep_scroll_amount',
      header: 'SCROLL AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'BRC NO.',
      dataIndex: 'brc_no',
      key: 'brc_no',
      header: 'BRC NO.' 
    },
    {
      title: 'DATE OF REALISATION',
      dataIndex: 'date_of_realization',
      key: 'date_of_realization',
      render: (text, record) => record.date_of_realization ? formatDateToDDMMYY(record.date_of_realization) : '', 
      header: 'DATE OF REALISATION' 
    },
    {
      title: 'PORT/FCC DIFF.',
      dataIndex: 'port_currency_difference',
      key: 'port_currency_difference',
      header: 'PORT/FCC DIFF.' 
    },
    {
      title: 'VALUE DIFF.',
      dataIndex: 'value_difference',
      key: 'value_difference',
      header: 'VALUE DIFF.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'FCC RELEASED IN (DAYS)',
      dataIndex: 'payment_released_in_days',
      key: 'payment_released_in_days',
      header: 'FCC RELEASED IN (DAYS)',
      align: 'right'
    },
    {
      title: `RODTEP TOBE SURRENDER (${formatDateToDDMMYY(new Date())})`,
      dataIndex: 'liability',
      key: 'liability',
      header: `RODTEP TOBE SURRENDER (${formatDateToDDMMYY(new Date())})`,
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'RODTEP SCRIP NO.',
      dataIndex: 'rodtep_scrip_number',
      key: 'rodtep_scrip_number',
      header: 'RODTEP SCRIP NO.' 
    },
    {
      title: 'RODTEP SCRIP DT.',
      dataIndex: 'rodtep_scrip_issue_date',
      key: 'rodtep_scrip_issue_date',
      render: (text, record) => record.rodtep_scrip_issue_date ? formatDateToDDMMYY(record.rodtep_scrip_issue_date) : '', 
      header: 'RODTEP SCRIP DT.',
      align: 'right'
    },
    {
      title: 'RODTEP SCRIP AMT.',
      dataIndex: 'rodtep_scrip_sb_amount',
      key: 'rodtep_scrip_sb_amount',
      header: 'RODTEP SCRIP AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'RODTEP SCRIP VALID TILL',
      dataIndex: 'rodtep_scrip_expiry_date',
      key: 'rodtep_scrip_expiry_date',
      render: (text, record) => record.rodtep_scrip_expiry_date ? formatDateToDDMMYY(record.rodtep_scrip_expiry_date) : '', 
      header: 'RODTEP SCRIP VALID TILL' 
    },
    {
      title: 'RODTEP REMARK',
      dataIndex: 'rodtep_remark',
      key: 'rodtep_remark',
      header: 'RODTEP REMARK' 
    },
    {
      title: 'RODTEP SCROLL TAT',
      dataIndex: 'rodtep_scroll_tat',
      key: 'rodtep_scroll_tat',
      header: 'RODTEP SCROLL TAT',
      align: 'right' 
    },
    {
      title: 'RODTEP SCRIP TAT',
      dataIndex: 'rodtep_script_tat',
      key: 'rodtep_script_tat',
      header: 'RODTEP SCRIP TAT',
      align: 'right'
    }
  ];

  const dashboardReportSummaryColumns = [
    {
      title: 'FY',
      dataIndex: 'fy',
      key: 'fy',
      header: 'FY' 
    },
    {
      title: 'IEC/BR CODE',
      dataIndex: 'iec_br',
      key: 'iec_br',
      header: 'IEC/BR CODE' 
    },
    {
      title: 'PORT CODE',
      dataIndex: 'port_code',
      key: 'port_code',
      header: 'PORT CODE' 
    },
    {
      title: 'RODTEP REMARK',
      dataIndex: 'rodtep_remark',
      key: 'rodtep_remark',
      header: 'RODTEP REMARK' 
    },
    {
      title: 'RODTEP AMT.',
      dataIndex: 'rodtep_amt',
      key: 'rodtep_amt',
      header: 'RODTEP AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'SCROLL PENDING - AMT.',
      dataIndex: 'scroll_pending_amt',
      key: 'scroll_pending_amt',
      header: 'SCROLL PENDING - AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'SCROLL GENERATED - AMT.',
      dataIndex: 'scroll_amt',
      key: 'scroll_amt',
      header: 'SCROLL GENERATED - AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'SCROLL AND SCRIP GENERATED AMT.',
      dataIndex: 'scroll_scrip_generated_amt',
      key: 'scroll_scrip_generated_amt',
      header: 'SCROLL AND SCRIP GENERATED AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'RODTEP LIABLITY AMT.',
      dataIndex: 'liability',
      key: 'liability',
      header: 'RODTEP LIABLITY AMT.',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    }
  ];
  

  const getSummaryDashboard = async () => {
    try{
        setIsLoading2(true)
        let reportType = 'RODTEP-Exp'
        const response = await getReportSummaryData(reportType);

        if(response){
            console.log("response: ", response.data);
            if(response.data){
                setSummaryDashboardData(response.data);
            }
        }
        else{
            console.log("getSummaryDashboard :: no reponse")
        }
        setIsLoading2(false)
    }
    catch(err){
        setIsLoading2(false)
        console.log(err);
    }  
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getRodtepDataService(iec_no);
      
        setJsonData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    getSummaryDashboard();
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const preprocessData = (data) => {
    return data.map(record => {

      if (record.sb_date) {
        const sbDate = new Date(record.sb_date);
        // Extract fiscal year from sb_date
        record.sb_date = formatDateToDDMMYY(sbDate);
      } 
      return record;
    });
  };

  const calculateDaysDifference = (date1, date2) => {
    if (!date1 || !date2) {
      return null; // or any other default value or behavior
    }

    const diffTime = Math.abs(new Date(date2) - new Date(date1));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        scrip_issue_date: getDateOnly(obj.scrip_issue_date),
        scrip_expiry_date: getDateOnly(obj.scrip_expiry_date),
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        rodtep_scrip_expiry_date: getDateOnly(obj.rodtep_scrip_expiry_date),
        rodtep_scrip_issue_date: getDateOnly(obj.rodtep_scrip_issue_date),
        date_of_realization: getDateOnly(obj.date_of_realization),
        rodtep_scroll_date: getDateOnly(obj.rodtep_scroll_date),
        leo_date: getDateOnly(obj.leo_date),
        sb_date: getDateOnly(obj.sb_date),
        rodtep_amt: obj.rodtep_amt ? parseFloat(obj.rodtep_amt) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - RoDTEP Status as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Lucrative's CIP Report - RoDTEP Status as on {formattedDate} of {username}</h4>
              </div>
              <div>
                <button
                 className='btn btn-primary'
                  onClick={exportToExcel}
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
            <div className="table-responsive">
              <Table
                  className="report-summary-table"
                  width={100}
                  columns={dashboardReportSummaryColumns} 
                  dataSource={summaryDashboardData}
                  pagination={false}
                  loading={isLoading2}
              />
            </div>
           
            <hr />
              <div className="table-responsive">
                <Table bordered columns={columns} dataSource={jsonData} loading={isLoading} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default RodtepReport;
