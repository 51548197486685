import React, {Fragment, useEffect, useState, useRef } from 'react';
import { Table } from 'antd';
import { getClearanceTimeImportReportService } from '../../../services/ClearanceReportService';
import Card from '../../../components/bootstrap/card';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import moment from 'moment';
import { getTodaysDate, formatToTwoDecimals } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const ClearanceTimeImportReport = () => {
  const [data, setData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const tableRef = useRef(null);
  const username = localStorage.getItem('name');

  useEffect(() => {
    fetchData();
  }, []);

  const getDataToRender = () => {
    if (tableRef.current) {
      const tableData = data;
      return tableData;
    }
    return [];
  };

  const fetchData = async () => {
    try {
      // Call the service function to fetch the clearance time import report data
      const response = await getClearanceTimeImportReportService(iec_no); // Define this service function
      const processedData = preprocessData(response);
      setData(processedData);
    } catch (error) {
      console.error('Error fetching clearance time import report data:', error);
    }
  };

  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  const preprocessData = (data) => {
    return data.map(record => {

      record.daysIgmSub = calculateDaysDifference(record.submission_date, record.igm_date);
      record.daysSubToOoc = calculateDaysDifference(record.ooc_date, record.submission_date);
      record.daysIgmToOoc = calculateDaysDifference(record.ooc_date, record.igm_date);
      return record;
    });
  };

  const calculateDaysDifference = (date1, date2) => {

    if (date1 && date2){
      const diffTime = Math.abs(new Date(date2) - new Date(date1));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      return diffDays;
    }
    else{
      return ''
    }
  };
  
  const columns = [
    {
      title: 'IEC/BR CODE',
      dataIndex: 'boe_branch_code',
      key: 'boe_branch_code',
      header: 'IEC/BR CODE',
    },
    {
      title: 'CB CODE',
      dataIndex: 'boe_cb_code',
      key: 'boe_cb_code',
      header: 'CB CODE',
    },
    {
      title: 'CB NAME',
      dataIndex: 'cb_name',
      key: 'cb_name',
      header: 'CB NAME',
    },
    {
      title: 'PORT',
      dataIndex: 'port_code',
      key: 'port_code',
      header: 'PORT',
    },
    {
      title: 'BOE NO.',
      dataIndex: 'be_no',
      key: 'be_no',
      header: 'BOE NO.',
    },
    {
      title: 'BOE DT.',
      dataIndex: 'boe_date',
      key: 'boe_date',
      header: 'BOE DT.',
    },
    {
      title: 'IGM DT.',
      dataIndex: 'igm_date',
      key: 'igm_date',
      header: 'IGM DT.',
    },
    {
      title: 'SUBMISSION DT.',
      dataIndex: 'submission_date',
      key: 'submission_date',
      header: 'SUBMISSION DT.',
    },
    {
      title: 'ASSESSMENT DT.',
      dataIndex: 'assessment_date',
      key: 'assessment_date',
      header: 'ASSESSMENT DT.',
    },
    {
      title: 'EXAMINATION DT.',
      dataIndex: 'examination_date',
      key: 'examination_date',
      header: 'EXAMINATION DT.',
    },
    {
      title: 'OOC DT.',
      dataIndex: 'ooc_date',
      key: 'ooc_date',
      header: 'OOC DT.',
    },
    {
      title: 'INTEREST PAID - INR',
      dataIndex: 'int',
      key: 'int',
      header: 'INTEREST PAID - INR',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'PENLTY PAID - INR',
      dataIndex: 'pnlty',
      key: 'pnlty',
      header: 'PENLTY PAID - INR',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'FINE PAID - INR',
      dataIndex: 'fine',
      key: 'fine',
      header: 'FINE PAID - INR',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    {
      title: 'DAYS SUB. TO IGM.',
      dataIndex: 'daysIgmSub',
      key: 'daysIgmSub',
      header: 'DAYS SUB. TO IGM.',
      align: 'right'
    },
    {
      title: 'DAYS SUB TO OOC',
      dataIndex: 'daysSubToOoc',
      key: 'daysSubToOoc',
      header: 'DAYS SUB TO OOC',
      align: 'right'
    },
    {
      title: 'DAYS IGM. TO OOC',
      dataIndex: 'daysIgmToOoc',
      key: 'daysIgmToOoc',
      header: 'DAYS IGM. TO OOC',
      align: 'right'
    },
  ];
  

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Data');


    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        submission_date: getDateOnly(obj.submission_date),
        boe_date: getDateOnly(obj.boe_date),
        igm_date: getDateOnly(obj.igm_date),
        assessment_date: getDateOnly(obj.assessment_date),
        examination_date: getDateOnly(obj.examination_date),
        ooc_date: getDateOnly(obj.ooc_date),
        int: obj.int ? parseFloat(obj.int) : '',
        pnlty: obj.pnlty ? parseFloat(obj.pnlty) : '',
        fine: obj.fine ? parseFloat(obj.fine) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - Clearance Time Import as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };


  return (
    <Fragment>
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Lucrative's CIP TAT -  Import Clearance as on {moment().format('DD/MM/YYYY')} of {username}</h4>
              </div>

            <div>
              <button  className="btn btn-primary" onClick={exportToExcel}>Export to Excel</button>
            </div>
          </Card.Header>
          <Card.Body className="px-0">
            <div className="table-responsive">
     <Table dataSource={data} columns={columns} ref={tableRef}/>
     </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
   
  );
};

export default ClearanceTimeImportReport;
