import React, { Fragment, useState, useEffect } from 'react';
import { iecWiseEPCGDetailsService } from '../../../services/EPCGReportService';
import { Table, Button, Spin, Alert } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { Excel } from 'antd-table-saveas-excel';
import Card from '../../../components/bootstrap/card';
import { getReportSummaryData } from '../../../services/FileDataService';
import { getTodaysDate, formatToTwoDecimals } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const EPCGReportComponent = () => {
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [error, setError] = useState(null);
  const [summaryDashboardData, setSummaryDashboardData] = useState([]);
  const iec_no = localStorage.getItem('iec_no');
  const username = localStorage.getItem('name');
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  const columns = [
    { title: 'EPCG. AUTH. NO.', dataIndex: 'auth_number', key: 'auth_number', header: 'EPCG. AUTH. NO.' },
    { 
      title: 'EPCG. AUTH. DT.', 
      dataIndex: 'date', 
      key: 'date', 
      header: 'EPCG. AUTH. DT.',
      render: (text, record) => record.date ? formatDateToDDMMYY(record.date) : '' 
    },
    { title: 'FY', dataIndex: 'fy', key: 'fy', header: 'FY' },
    { 
      title: 'EO IN INR', 
      dataIndex: 'eo_fcc', 
      key: 'eo_fcc', 
      header: 'EO IN INR', 
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { title: 'IEC/BR CODE', dataIndex: 'iec_br', key: 'iec_br', header: 'IEC/BR CODE' },
    { title: 'PORT', dataIndex: 'port_code', key: 'port_code', header: 'PORT' },
    { title: 'REF. CODE', dataIndex: 'reference_code', key: 'reference_code', header: 'REF. CODE' },
    { title: 'SHB NO.', dataIndex: 'sb_no', key: 'sb_no', header: 'SHB NO.' },
    { title: 'SHB DT.', dataIndex: 'sb_date', key: 'sb_date', header: 'SHB DT.' },
    { title: 'HSN CODE', dataIndex: 'hs_cd', key: 'hs_cd', header: 'HSN CODE' },
    { title: 'DESCRIPTION', dataIndex: 'description', key: 'description', header: 'DESCRIPTION' },
    { title: 'VALUE IN INR', 
      dataIndex: 'fob_value', 
      key: 'fob_value', 
      header: 'VALUE IN INR', 
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'BRC NO.', dataIndex: 'brc_no', key: 'brcNo', header: 'BRC NO.' },
    { 
      title: 'DATE OF REALISATION', 
      dataIndex: 'date_of_realization', 
      key: 'date_of_realization', 
      header: 'DATE OF REALISATION',
      render: (text, record) => record.date_of_realization ? formatDateToDDMMYY(record.date_of_realization) : '' 
    },
    { title: 'VALUE', dataIndex: 'realised_value', key: 'realised_value', header: 'VALUE', align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'FCC', dataIndex: 'currency', key: 'currency', header: 'FCC' },
    { title: 'PORT/CURRENCY DIFFERENCE', dataIndex: 'port_currency_difference', key: 'port_currency_difference', header: 'PORT/CURRENCY DIFFERENCE' },
    { title: 'VALUE DIFF', dataIndex: 'value_difference', key: 'value_difference', header: 'VALUE DIFF', align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'RELEASED IN (DAYS)', dataIndex: 'payment_released_in_days', key: 'payment_released_in_days', header: 'RELEASED IN (DAYS)', align: 'right' },
    { title: 'OBSERVATION', dataIndex: 'brc_remark', key: 'brc_remark', header: 'OBSERVATION' }
  ];
  

  const dashboardReportSummaryColumns = [
    { title: 'EPCG AUTH. NO.', dataIndex: 'auth_number', key: 'auth_number', header: 'EPCG AUTH. NO.' },
    { title: 'FY', dataIndex: 'fy', key: 'fy', header: 'FY' },
    { title: 'EPCG AUTH DT.', dataIndex: 'date', key: 'date', header: 'EPCG AUTH DT.' },
    { title: 'HSN CODE', dataIndex: 'hs_cd', key: 'hs_cd', header: 'HSN CODE' },
    { title: 'EO-VALUE IN INR', dataIndex: 'eo_fcc', key: 'eo_fcc', header: 'EO-VALUE IN INR', align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'EO-FULFILLED VALUE', dataIndex: 'eo_fulfilled_val', key: 'eo_fulfilled_val', header: 'EO-FULFILLED VALUE', align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'EO-FULFILLED REALISED VALUE', dataIndex: 'eo_fulfilled_realised_val', key: 'eo_fulfilled_realised_val', header: 'EO-FULFILLED REALISED VALUE', align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
     },
    { title: 'BAL. EO AS PER SHB', dataIndex: 'bal_eo_shb', key: 'bal_eo_shb', header: 'BAL. EO AS PER SHB', align: 'right' },
    { title: 'BAL. EO AS PER BRC', dataIndex: 'bal_eo_brc', key: 'bal_eo_brc', header: 'BAL. EO AS PER BRC', align: 'right' },
    { title: 'BAL. EOP', dataIndex: 'bal_eop', key: 'bal_eop', header: 'BAL. EOP', align: 'right' },
    { title: 'Remark', dataIndex: 'brc_remark', key: 'brc_remark', header: 'Remark' }
  ];

  const getSummaryDashboard = async () => {
    try {
      setIsSummaryLoading(true);
      const response = await getReportSummaryData('EPCG-Auth-EO');
      if (response?.data) {
        setSummaryDashboardData(response.data);
      } else {
        console.log('getSummaryDashboard :: no response');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await iecWiseEPCGDetailsService(iec_no);
        setJsonData(response);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getSummaryDashboard();
    fetchData();
  }, [iec_no]);

  const preprocessData = (data) => {
    return data.map((record) => {
      if (record.sb_date) {
      } else {
        // record.FY = '';
        record.sb_date = '';
      }
      return record;
    });
  };

  const formatDateToDDMMYY = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let summaryWorksheet = workbook.addWorksheet('Summary');
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export
    summaryWorksheet.columns = dashboardReportSummaryColumns;
    // Apply styles to the header row (Row 1)
    summaryWorksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle'}; // Center align
    });

    summaryDashboardData.forEach((obj) => {
      summaryWorksheet.addRow({
        ...obj,
        date: getDateOnly(obj.date) 
      })
    })

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        date: getDateOnly(obj.date),
        sb_date: getDateOnly(obj.sb_date),
        leo_date: getDateOnly(obj.leo_date),
        date_of_realization : getDateOnly(obj.date_of_realization),
        eo_fcc: obj.eo_fcc ? parseFloat(obj.eo_fcc) : '',
        fob_value: obj.fob_value ? parseFloat(obj.fob_value) : '',
        realised_value: obj.realised_value ? parseFloat(obj.realised_value) : '',
        value_difference: obj.value_difference ? parseFloat(obj.value_difference) : '',
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - EPCG Authorisation EO as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  Lucrative's CIP DSS - EPCG Authorisation EO as on {formattedDate} of {username}
                </h4>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  disabled={isLoading || error}
                  onClick={exportToExcel}
                >
                  Export to Excel
                </button>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              {error && (
                <div className="text-center my-4">
                  <Alert message="Error" description={error} type="error" showIcon />
                </div>
              )}
              <Spin spinning={isSummaryLoading} tip="Loading summary...">
                <div className="table-responsive">
                  <Table
                    className="report-summary-table"
                    columns={dashboardReportSummaryColumns}
                    dataSource={summaryDashboardData}
                    pagination={false}
                  />
                </div>
              </Spin>
              <hr />
              <Spin spinning={isLoading} tip="Loading EPCG data...">
                <div className="table-responsive">
                  <Table bordered columns={columns} dataSource={jsonData} />
                </div>
              </Spin>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EPCGReportComponent;
