import React, { useEffect, useState, Fragment } from 'react';
import { Table, Spin, Button } from 'antd';
import { getFtaDataService } from '../../../services/FTAReportService';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { Excel } from 'antd-table-saveas-excel';
import { Col, Row } from 'react-bootstrap';
import Card from '../../../components/bootstrap/card';
import { getTodaysDate, formatToTwoDecimals } from '../../../utilities/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const FtaReport = () => {
  const iec_no = localStorage.getItem('iec_no');
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const username = localStorage.getItem('name');

  // Define columns for the table
  const columns = [
    { 
      title: 'BRANCH CODE', 
      dataIndex: 'iec_br', 
      key: 'iec_br', 
      header: 'BRANCH CODE' 
    },
    { 
      title: 'PORT CODE', 
      dataIndex: 'port_code', 
      key: 'port_code', 
      header: 'PORT CODE' 
    },
    { 
      title: 'FY', 
      dataIndex: 'fy', 
      key: 'fy', 
      header: 'FY' 
    },
    { 
      title: 'BOE NO.', 
      dataIndex: 'be_no', 
      key: 'be_no', 
      header: 'BOE NO.' 
    },
    { 
      title: 'BOE DT', 
      dataIndex: 'be_date', 
      key: 'be_date', 
      header: 'BOE DT', 
      render: (text, record) => {
        if (record.be_date) {
          return formatDateToDDMMYY(record.be_date);
        }
      } 
    },
    { 
      title: 'REF CODE.', 
      dataIndex: 'reference_code', 
      key: 'reference_code', 
      header: 'REF CODE.' 
    },
    { 
      title: 'COUNTRY CODE', 
      dataIndex: 'coo', 
      key: 'coo', 
      header: 'COUNTRY CODE' 
    },
    { 
      title: 'HSN CODE', 
      dataIndex: 'cth_item_detail', 
      key: 'cth_item_detail', 
      header: 'HSN CODE' 
    },
    { 
      title: 'DESCRIPTION', 
      dataIndex: 'description', 
      key: 'description', 
      header: 'DESCRIPTION' 
    },
    { 
      title: 'ASSE. VALUE IN INR', 
      dataIndex: 'assess_value', 
      key: 'assess_value', 
      header: 'ASSE. VALUE IN INR',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'BCD RATE', 
      dataIndex: 'bcd_rate', 
      key: 'bcd_rate', 
      header: 'BCD RATE',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'BCD AMT', 
      dataIndex: 'bcd_amount', 
      key: 'bcd_amount', 
      header: 'BCD AMT',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'FTA BCD RATE', 
      dataIndex: 'rate_of_duty_percent', 
      key: 'rate_of_duty_percent', 
      header: 'FTA BCD RATE',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'APPLI. DUTY', 
      dataIndex: 'applied_duty', 
      key: 'applied_duty', 
      header: 'APPLI. DUTY',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'BCD SAVING(Cumulative Cess & IGST is not considered)', 
      dataIndex: 'duty_saving', 
      key: 'duty_saving', 
      header: 'BCD SAVING(Cumulative Cess & IGST is not considered)',
      align: 'right',
      render: (text, record) => {
        return formatToTwoDecimals(text)
      }
    },
    { 
      title: 'NOTI. NO. & DATE', 
      dataIndex: 'notification_no', 
      key: 'notification_no', 
      header: 'NOTI. NO. & DATE' 
    },
    { 
      title: 'ITEM SR. NO.', 
      dataIndex: 'item_sr_no', 
      key: 'item_sr_no', 
      header: 'ITEM SR. NO.',
      align: 'right'
    },
    { 
      title: 'AGREEMENT', 
      dataIndex: 'agreement', 
      key: 'agreement', 
      header: 'AGREEMENT' 
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getFtaDataService(iec_no);
        setJsonData(response);
      } catch (error) {
        setError('Error fetching FTA data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [iec_no]);
  const preprocessData = (data) => {
    return data.map(record => {
      if (record.be_date) {
        const beDate = new Date(record.be_date);
        // Extract fiscal year from sb_date
        const fiscalYearStartYear = beDate.getMonth() >= 3 ? beDate.getFullYear() : beDate.getFullYear() - 1;
        const fiscalYearEndYear = fiscalYearStartYear + 1;
        const fiscalYear = `AM${fiscalYearEndYear.toString().slice(-2)}`;
        record.FY = fiscalYear;
        // Format sb_date

        record.beDate = formatDateToDDMMYY(beDate);
      } else {
        record.FY = '';
        record.be_date = '';
        record.beDate = '';
      }
      return record;
    });
  };
  function formatDateToDDMMYY(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ''; // Return empty string if the dateString is not a valid date
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }

  const getDateOnly = (dateString) => {
    if(dateString && dateString.length !== 0){
      const date = new Date(dateString);
      return date;
    }
    else{
      return '';
    }
  }

  const exportToExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet('Data');
    var data = jsonData;

    // Define columns for Excel export

    worksheet.columns = columns;
    // Apply styles to the header row (Row 1)
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true }; // Bold text
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFC000' }, // Yellow background
      };
      cell.alignment = { vertical: 'middle' }; // Center align
    });

    data.forEach((obj) => {
      worksheet.addRow({
        ...obj,
        be_date: getDateOnly(obj.be_date),
        leo_date: getDateOnly(obj.leo_date),
        bcd_rate: obj.bcd_rate ? parseFloat(obj.bcd_rate) : '',
        bcd_amount: obj.bcd_amount ? parseFloat(obj.bcd_amount) : '',
        scroll_amount: obj.scroll_amount ? parseFloat(obj.scroll_amount) : '',
        applied_duty: obj.applied_duty ? parseFloat(obj.applied_duty) : '',
        assess_value: obj.assess_value ? parseFloat(obj.assess_value) : ''
      })
      
    });

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Use FileSaver to save the file
    saveAs(blob, `Lucrative's CIP DSS - FTA Opportunities as on ${getTodaysDate()} of ${iec_no}.xlsx`);
  };

  return (
    <Fragment>
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">Lucrative's CIP DSS - FTA Opportunities as on {moment().format('DD/MM/YYYY')} of {username}</h4>
            </div>
            <div>
              <button
               className='btn btn-primary'
                onClick={exportToExcel}
              >
                Export to Excel
              </button>
            </div>
          </Card.Header>
          <Card.Body className="px-0">   
            <div className="table-responsive">
              <Table bordered columns={columns} dataSource={jsonData} loading={isLoading} />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Fragment>
   
  );
};

export default FtaReport;
