import React, { useEffect, useState } from 'react';
import { Form, Input, DatePicker, Upload, Button, Card, Select, Table, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Row, Col } from 'react-bootstrap'
import axios from 'axios';
import { SERVER_URL } from '../../../const';
import { getAllAdminIECsService } from '../../../services/FileDataService'

const { TextArea } = Input;
const { Option } = Select;
const token = localStorage.getItem('token');
const iec_no = localStorage.getItem('iec_no')
const BondFormComponent = () => {
  const [bondTypes, setBondTypes] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [userRole, setUserRole] = useState(localStorage.getItem('role'))
  const [IECOptions, setIECOptions] = useState([]);
  const [selectedIEC, setSelectedIEC] = useState(localStorage.getItem('iec_no'));
  const [iecName, setIECName] = useState(localStorage.getItem('name'));

  useEffect(() => {
    // Fetch bond types from the backend API
    fetchBondData();
    fetchBondTypes();
    getAdminIEC();
  }, []);

  const handleIECSelection = (val) => {
    console.log(val)
    if (val){
      let iec_no = val.split(";")[0];
      let iec_name = val.split(";")[1];
      setSelectedIEC(iec_no)
      setIECName(iec_name)
    }
    else{
      setSelectedIEC(null);
      setIECName('');
    }
  }

  const getAdminIEC = async () => {
    const token = localStorage.getItem('token');
    const response = await getAllAdminIECsService(token);

    if (response && response.data){
      let i = 0;
      const options = response.data.map(item => ({
        value: `${item.iec_no};${item.fullname}`,
        label: `${item.iec_no} (${item.fullname})`,
        key: i++,
      }));

      setIECOptions(options);
    }
    
  }

  const fetchBondData = async () => {
    try {
      setLoading(true);
      let get_iec = iec_no;
      if (userRole == 'superadmin'){
        get_iec = 'all'
      }
      const response = await axios.get(`${SERVER_URL}/get-bond-forms?iec_no=${get_iec}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      // Set the parsed data
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Bond Type',
      dataIndex: 'bond_type',
      key: 'bond_type',
    },
    {
      title: 'BG/Bond No',
      dataIndex: 'bond_no',
      key: 'bond_no',
    },
    {
      title: 'Bond Name',
      dataIndex: 'bond_name',
      key: 'bond_name',
    },
    {
      title: 'Bond Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Submitted To',
      dataIndex: 'submitted_to',
      key: 'submitted_to',
    },
    {
      title: 'File No',
      dataIndex: 'file_no',
      key: 'file_no',
    },
    {
      title: 'Accepted Date',
      dataIndex: 'accepted_date',
      key: 'accepted_date',
    },
    {
      title: 'BG/Bond Value',
      dataIndex: 'bond_value',
      key: 'bond_value',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
    },
    {
      title: 'Obligation Base',
      dataIndex: 'obligation_base',
      key: 'obligation_base',
    },
  ];

  const fetchBondTypes = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/get-bond-types`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setBondTypes(JSON.parse(response.data));
    } catch (error) {
      console.error('Error fetching bond types:', error);
    }
  };

  const onFinish = async (values) => {
    setLoader(true);
    // Convert DatePicker values to appropriate format
    values.accepted_date = values.accepted_date.format('YYYY-MM-DD');
    values.expiry_date = values.expiry_date.format('YYYY-MM-DD');
    values.date = values.date.format('YYYY-MM-DD');
    values.iec_no = selectedIEC;
    // Add bond_type_id to values
    const selectedBondType = bondTypes.find(bondType => bondType.bond_type_code === values.bond_type);
    if (selectedBondType) {
      values.bond_type_id = Number(selectedBondType.bond_type_id);
    }

    try {
      const response = await axios.post(`${SERVER_URL}/submit-bond-form`, values, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      fetchBondData();
      form.resetFields();
      setLoader(false);
      //message.success(response.data.message);
    } catch (error) {
      setLoader(false);
      console.error('Error submitting form:', error);
      //message.error('Form submission failed');
    }
  };

  return (
    <div>
      <Card title="Bond">
        <Spin spinning={loader}>

        {userRole && userRole == 'superadmin' &&
        <Form form={form} name="bond_report" onFinish={onFinish}>
          <Row>
            <Col lg="6" md="6">
            <Form.Item label="Select IEC " name="select_iec">

              <Select
                  focus
                  showSearch
                  placeholder="Select IEC"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={handleIECSelection}
                  allowClear
                  options={IECOptions}
                  style={{ width: '300px' }}
                />   
              
            </Form.Item>
            
            </Col>
            <Col lg="6" md="6">
            <h6 style={{display: 'inline-block', textAlign: 'center'}}><b>{iecName}</b></h6>
              &nbsp;&nbsp;&nbsp;
              <strong>{selectedIEC}</strong>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Form.Item label="Type of Bond" name="bond_type" rules={[{ required: true }]}>
              <Select>
                {bondTypes.map(bondType => (
                  <Option key={bondType.bond_type_id} value={bondType.bond_type_code}>
                    {bondType.bond_type_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            </Col>
            <Col lg="6" md="6">
              <Form.Item label="Name" name="bond_name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col lg="6" md="6">
              <Form.Item label="Bond Date" name="date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col lg="6" md="6">
              <Form.Item label="Submitted to" name="submitted_to" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Form.Item label="File No." name="file_no" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col lg="6" md="6">
              <Form.Item label="BG/Bond No." name="bond_no" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Form.Item label="Accepted Date" name="accepted_date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col lg="6" md="6">
              <Form.Item label="BG or Bond Value" name="bond_value" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <Form.Item label="Date of Expiry" name="expiry_date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col lg="6" md="6">
                
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12">
              <Form.Item label="Obligation Base" name="obligation_base" rules={[{ required: true }]}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            
          </Row>
          
          
          {/* <Form.Item label="Upload Copy" name="upload_copy">
            <Upload>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item> */}
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
        }
        </Spin>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => record.id} // Assuming each record has a unique 'id'
        />
      </Card>
    </div>
  );
};

export default BondFormComponent;
